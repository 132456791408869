.card {
  position: relative;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  background-color: white;
  cursor: pointer;
  font-size: calc(var(--info-font-size) + 2px);
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  overflow: hidden;
  .song {
    position: absolute;
    font-weight: bold;
    width: 100%;
    text-align: center;
    top: -170px;
    right: -1px;
    box-shadow: 0 0 5px 2px rgba(157, 157, 157, 0.871);
    height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 70px;
  }
  .singer {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--info-color);
    margin-top: 5px;
  }
  .composer {
    display: flex;
    align-items: center;
    span::before {
      font-size: 12px;
    }
  }
  .infos {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    font-size: calc(var(--info-font-size) - 1px);
    color: var(--info-color);
  }
}
