.permission-table,
.permission-inner {
  width: 100%;
}

.permission-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--info-color);
  padding: 10px;
}

.permission-item:last-child {
  border-bottom: 0;
}

.permission-title {
  width: 20%;
}

.permission-action {
  width: 80%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.permission-action-item {
  display: flex;
  align-items: center;
  /* width: calc(100% / 7); */
  width: 210px;
  padding: 0 10px;
  border-right: 1px solid var(--info-color);
}

.permission-title {
  font-weight: bold;
}
.permission-action-check {
  width: 100%;
}

.permission-action-check label {
  opacity: 0.7;
}

@media (max-width: 970px) {
  .permission-action,
  .permission-title {
    width: 50%;
  }
}

@media (max-width: 527px) {
  .permission-item {
    flex-direction: column;
  }

  .permission-action,
  .permission-title {
    width: 100%;
  }
}
