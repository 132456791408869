.nav {
  background-color: var(--primary-color);
  .brand {
    font-size: var(--head-font-size);
    color: var(--info-color);
    margin-right: 10px;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
