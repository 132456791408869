.selected_lang {
  /* position: absolute;
  right: 15px; */
  position: relative;
}

.language_list {
  position: absolute;
  z-index: 2000;
  top: 140%;
  left: -10px;
  /* width: 40px; */
  background-color: white;
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
}

.selected_item {
  width: 32px !important;
  height: 26px;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-weight: bold;
  background-color: white;
  font-size: var(--info-font-size);
  border: none;
  outline: none;
  cursor: pointer;
}

.selected_item img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
}

.language_item {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  width: 100%;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--info-font-size);
  font-weight: bold;
  color: black;
}

.language_item:hover {
  color: var(--primary-color);
  background-color: var(--secondary-color);
}

.language_item img {
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
  width: 25px;
  height: 25px;
}

@media (max-width: 600px) {
  .language_list {
    position: absolute;
    top: 35px;
    width: 70px;
    left: -23px;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 1px solid var(--secondary-color);
  }

  .selected_item {
    justify-content: center;
    padding: 0;
  }
  .selected_item img {
    width: 30px;
    height: 30px;
  }

  .language_item {
    padding: 10px 12px;
    font-size: 0.6em !important;
  }

  .language_item:hover {
    color: var(--primary-color);
  }

  .language_item img {
    margin-right: 20px;
    margin-left: 15px;
  }
}

@media (max-width: 850px) and (min-width: 600px) {
  .language_list {
    position: absolute;
    top: 120%;
    width: 60% !important;
    right: 17%;
    background-color: white;
  }

  .selected_item {
    justify-content: center;
  }

  .language_item {
    padding: 5px 24px;
    font-size: 0.8em !important;
  }

  .language_item:hover {
    color: var(--primary-color);
  }

  .language_item img {
    margin-right: 20px;
    margin-left: 15px;
  }
}
