.card {
  width: 100%;
  //   height: 300px;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  img {
    object-fit: cover;
  }
  .thumb {
    position: relative;
    padding: 0;
    img {
      transform: translateX(5px);
      transform: translateY(9px);
    }
    &:hover .overlay {
      transform: scale(1);
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: 0.2s ease-in all;
      transform: scale(0);
      display: flex;
      z-index: 2;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.2);
      color: white;
      cursor: pointer;
      font-size: var(--text-font-size);
    }
  }
  .container {
    display: flex;
    // height: 300px;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--card-padding);
    .header {
      display: flex;
      justify-content: space-between;
      .typography {
        .record {
          font-size: var(--title-font-size);
          line-height: var(--title-line-height);
          font-weight: normal;
        }
        .author {
          font-size: var(--text-font-size);
          line-height: var(--text-line-height);
          font-weight: normal;
          color: var(--info-color);
        }
      }
      .actions {
        display: flex;
        .core,
        .report {
          display: flex;
          button {
            border: none;
          }
        }
        .report {
          margin-right: 40px;
          button {
            width: 50px;
            height: 50px !important;
          }
        }
      }
    }
    .body {
      .info {
        margin: 5px 0;
        dt,
        dd {
          font-size: var(--text-font-size);
          line-height: var(--text-line-height);
          text-transform: uppercase;
          color: var(--info-color);
          display: inline-block;
          font-weight: 400;
          opacity: 0.7;
        }
        dd {
          margin-left: 10px;
        }
      }
    }
  }
}

.thumb_area {
  width: 100%;
  input {
    display: none;
  }
  .upload_thumb {
    background-color: rgba(0, 0, 0, 0.06);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    opacity: 0.7;
    cursor: pointer;
    border-radius: 4px;
  }
}

.cropped_thumb {
  // height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    object-fit: contain;
  }
}

@media (max-width: 976px) {
  .card {
    .actions {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      .report {
        margin-right: 0 !important;
      }
    }
  }
}
