.activate-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.activate-page span {
  font-size: 90px;
  margin: 15px 0;
  color: rgb(70 206 132);
}
.activate-page h1 {
  margin: 10px 0;
  font-weight: lighter;
}
.activate-page a {
  font-size: large;
  border: 1px solid;
  border-radius: 4px;
  padding: 10px 20px;
  margin-top: 15px;
  transition: 0.4s ease-in-out all;
}
.activate-page a:hover {
  transform: translateY(-5px);
}
