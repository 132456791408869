.card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  margin-top: 10px;
  .img {
    max-width: 165px;
    max-height: 165px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    color: var(--info-color);
    .icon::before {
      color: rgb(70 206 132) !important;
      margin: 0 5px;
    }
  }
}

@media (max-width: 398px) {
  .card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    margin-top: 10px;
    .img {
      max-width: 125px;
      max-height: 125px;
    }
    .info {
      display: flex;
    }
  }
}
