@font-face {
  font-family: 'Roboto Thin';
  src: url('Roboto-Thin.ttf');
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('Roboto-Thin.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: 'Roboto';
  src: url('Roboto-ThinItalic.ttf');
  src: local('Roboto ThinItalic'), local('Roboto-ThinItalic'),
    url('Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
} */

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Regular.ttf');
  src: local('Roboto Regular'), local('Roboto-Regular'),
    url('Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Medium';
  src: url('Roboto-Medium.ttf');
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Black';
  src: url('Roboto-Black.ttf');
  src: local('Roboto Black'), local('Roboto-Black'),
    url('Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: 'Roboto';
  src: url('Roboto-BlackItalic.ttf');
  src: local('Roboto Black Italic'), local('Roboto-Black-Italic'),
    url('Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
} */
