.card {
  width: 100%;
  padding: 5px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  .img {
    object-fit: cover;
  }
  .content {
    margin-left: 10px;
    .author {
      font-size: var(--text-font-size);
      line-height: var(--text-line-height);
      font-weight: normal;
    }
    .title {
      font-size: var(--info-font-size);
      line-height: var(--info-line-height);
      padding: 3px 5px !important;
      margin: 2px 5px 0 0;
      span {
        margin: 0;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
