@import './fonts/Roboto/fonts.css';

:root {
  --primary-color: #282828;
  --secondary-color: #e5e5e5;
  --dark-color: #0f1826;
  --text-color: #343a40;
  --info-color: #808080;
  --bg-color: #f2f2f2;
  --gray-bg-color: #e5e5e5;

  /* font size template */
  --head-font-size: 28px;
  --title-font-size: 24px;
  --text-font-size: 16px;
  --about-font-size: 14px;
  --info-font-size: 10px;

  --font-family: 'Roboto', sans-serif;
  --font-family-thin: 'Roboto Thin', sans-serif;
  --font-family-regular: 'Roboto', sans-serif;

  /* line height template */
  --head-line-height: 32px;
  --title-line-height: 30px;
  --text-line-height: 24px;
  --about-line-height: 18px;
  --info-line-height: 15px;

  /* margin & padding template */
  --main-margin: 1rem;
  --container-padding: 72px;
  --card-padding: 24px;

  /* border radius template */
  --button-radius: 6px;
  --small-radius: 3px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family) !important;
  scroll-behavior: smooth !important;
  transition: 0.4s ease-out all;
  font-display: swap;
  background-color: var(--bg-color);
}

a {
  color: inherit;
  text-decoration: none !important;
  font-family: var(--font-family) !important;
}

* {
  box-sizing: border-box;
}

input,
input::placeholder {
  font-family: var(--font-family) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
  /* line-height: var(--text-line-height); */
  font-display: swap;
}
p,
div {
  font-size: var(--text-font-size);
  line-height: var(--text-line-height);
}

.container {
  width: 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 0 15px;
}

@media (min-width: 575.98px) {
  .container {
    max-width: 540px;
    margin: auto;
    padding: 0;
  }
}

@media (min-width: 767.98px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 991.98px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1199.98px) {
  .container {
    max-width: 97%;
  }
}

.container .container {
  max-width: 100%;
}
