/* ===========Global Styles=========== */

.p-breadcrumb {
  border: none;
  background-color: transparent;
  padding: 10px 0;
}

.p-breadcrumb .p-menuitem-text {
  font-size: var(--text-font-size);
}
.p-breadcrumb .pi-chevron-right {
  padding-bottom: 5px;
}
.p-breadcrumb .pi-chevron-right:before {
  font-size: 10px !important;
}

/* ===========Global Styles=========== */

/* ===========Navbar Styles=========== */

.navbar-menu .p-menubar {
  background-color: var(--primary-color);
  border: none;
  outline: none;
  padding: 0;
}

.navbar-menu .p-menuitem,
.navbar-menu .p-menuitem-link:focus {
  outline: none !important;
  border: none;
  box-shadow: none !important;
}

/* .navbar-menu .p-menuitem-link:hover,
.navbar-menu
  .p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover,
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background-color: transparent !important;
} */

.navbar-menu .p-menuitem-icon,
.navbar-menu .p-menuitem-text,
.navbar-menu .p-submenu-icon {
  color: white !important;
  font-size: var(--text-font-size);
  line-height: var(--text-line-height);
}
.navbar-menu .p-submenu-list {
  background-color: var(--primary-color);
}

.p-submenu-list {
  padding: 0 !important ;
}

.navbar-menu .p-menuitem a:hover {
  background-color: #5a5a5a !important;
}

.navbar-menu .p-button {
  border-radius: 20px;
  outline: none;
  border: none;
  box-shadow: none;
  background-color: white;
  color: black;
  margin-right: 10px;
  display: flex;
  justify-content: center;
}

.p-button {
  outline: none;
  border: none;
  box-shadow: none;
}

.p-menubar-button {
  color: white !important;
}

.p-menubar .p-submenu-list {
  width: 100%;
}

.p-submenu-list .p-menuitem a {
  transition: 0.4s ease-out all;
}

.p-submenu-list .p-menuitem {
  background-color: white;
}
.p-submenu-list .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: black !important;
  font-weight: lighter;
  font-size: calc(var(--info-font-size) + 3px);
  transition: 0.4s ease-out all;
}
.navbar-menu .p-submenu-list .p-menuitem a:hover .p-menuitem-text {
  color: white !important;
}
.navbar-menu .p-submenu-list .p-menuitem a:hover {
  background-color: #5a5a5a !important;
}

@media screen and (max-width: 960px) {
  .p-menubar .p-menubar-root-list {
    background-color: var(--primary-color) !important;
  }
  .p-menubar .p-menubar-button:hover {
    background-color: transparent;
    outline: none;
    box-shadow: none;
  }
  .navbar-menu .p-button-icon-left {
    margin: 0;
  }
  .navbar-menu .p-button-label {
    display: none;
  }
}

/* ===========Navbar Styles=========== */

/* ===========Author Card Styles=========== */

.author-card .p-button {
  width: 30px;
  height: 30px !important;
}

/* ===========Author Card Styles=========== */

/* ===========Author Card Styles=========== */

.custom-table,
.custom-table .p-datatable {
  width: 100%;
  background-color: white;
  z-index: 9;
}

.custom-table .p-datatable tr {
  text-align: center;
  border-top: 0.5px solid rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.p-datatable td,
.p-datatable tr {
  font-size: calc(var(--info-font-size) + 2px);
  overflow: hidden;
}

/* ===========Author Card Styles=========== */

/* ===========Audio tags Styles ============ */

.custom-table audio {
  /* max-width: 200px; */
}

.hide-player {
  display: none !important;
}

.show-player {
  display: flex;
}

.custom-audio {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.custom-audio span {
  font-size: calc(var(--info-font-size) + 2px);
}

.custom-audio img {
  cursor: pointer;
}
.custom-audio div {
  margin-left: 15px;
  display: flex;
}

audio::-webkit-media-controls-panel,
audio::-webkit-media-controls-timeline-container {
  /* background-color: white !important; */
  display: flex;
  /* justify-content: space-between; */
}
audio::-webkit-media-controls-panel {
  background-color: red !important;
}
/* audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-timeline-container,
audio::-webkit-media-controls-mute-button,
audio::-webkit-media-controls-timeline,
audio::-webkit-media-controls-volume-slider-container {
  display: none;
} */

/* ===========Inputs tags Styles ============ */

.p-inputtext,
.p-dropdown {
  border-top: none;
  border-right: none;
  border-left: none;
  outline: none;
  border-radius: 0;
  width: 100%;
  font-size: calc(var(--info-font-size) + 3px);
  background-color: transparent;
}

.p-dropdown,
.p-inputtext:enabled:focus {
  box-shadow: none;
}

input[type='date'] {
  padding: 13.7px;
}

/* input[type='date']:-webkit-any(input) {
  padding: 16px;
} */

input[type='date']:-moz-any(input) {
  padding: 16px;
}

.p-dropdown .p-dropdown-label,
.p-dropdown .p-dropdown-label.p-placeholder {
  padding: 12px;
}
.p-float-label label {
  background-color: transparent !important;
  left: 5px !important;
}

.p-float-label > label,
.p-float-label label {
  left: 5px !important;
}

.p-calendar-w-btn {
  background-color: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
  padding: 0.3px;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext,
.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
  border-radius: 0 !important;
}

.p-inputmask {
  border-radius: 0 !important;
}

.p-inputgroup-addon {
  border-left: 0;
  border-top: 0;
  padding: 10px;
}

.p-password {
  width: 100%;
}

/* ===========Dropdown tags Styles ============ */

.p-dropdown {
  border-top: none;
  border-right: none;
  border-left: none;
  outline: none;
  border-radius: 0;
  width: 100%;
  font-size: var(--info-font-size);
  background-color: transparent;
}

.p-dropdown:enabled:focus {
  box-shadow: none;
}

.p-dialog .p-dropdown .p-dropdown-label,
.p-dialog .p-dropdown .p-dropdown-label.p-placeholder {
  padding: 0 12px 8px 12px;
}

/* ===========Datatable tags Styles ============ */
.datatable-responsive-demo {
  overflow: hidden;
}

.p-datatable-wrapper {
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.p-datatable-wrapper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.datatable-responsive-demo
  .p-datatable-responsive-demo
  .p-datatable-tbody
  > tr
  > td
  .p-column-title {
  display: none;
}

.p-datatable .p-datatable-thead > tr > th {
  font-weight: bolder;
  white-space: nowrap;
}
.card .p-datatable .p-datatable-thead > tr > th,
.card .p-datatable .p-datatable-thead > tr > td,
.card .p-datatable .p-datatable-tbody > tr > td {
  text-align: center !important;
  white-space: nowrap;
}

.p-paginator-right-content,
.p-paginator-left-content {
  display: none;
}

@media screen and (max-width: 40em) {
  .p-datatable table {
    width: auto;
  }
  .p-paginator-current {
    width: 53%;
  }
}

/* ===========Button Styles ============ */

.p-button-icon-only {
  border: none !important;
}

.p-button.p-button-outlined:not(a):not(.p-disabled):hover {
  background: rgba(63, 81, 181, 0.16);
}

.p-button.p-button-danger.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger
  > .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger
  > .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(a):not(.p-disabled):hover {
  background: rgba(211, 47, 47, 0.16);
}

/* ===========Singer Item Styles ============ */

.attached-files {
  width: 100%;
  height: 100%;
  padding-top: 15px;
}

/* ===========Login Styles ============ */

.login {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.login-form,
.login-img {
  width: 50%;
}

.login-img {
  background-image: url('/src/styles/img/login.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  box-shadow: -13px 0px 20px 0px;
}

.login-form {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.login .p-password {
  width: 100%;
}

@media (max-width: 978px) {
  .login {
    flex-direction: column;
    justify-content: center;
    padding: 15px;
  }
  .login-form,
  .login-img {
    width: 100%;
  }
  .login-img {
    display: none;
  }
}

/* ===========Overlay Styles ============ */

.overlay-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.overlay-list span {
  padding: 10px 5px;
  cursor: pointer;
}

.overlay-list span:hover {
  color: rgba(63, 81, 181, 0.92);
}

/* ===========Dropzone styles ========== */

.dropzone {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  opacity: 0.6;
}

.dropzone p {
  font-size: calc(var(--info-font-size) + 3px);
}

.report-lists {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.report-item {
  display: flex;
  justify-content: space-between;
}

.report-action {
  display: flex;
}

/* ===========Inplace design=========== */

.p-inplace-content {
  display: flex !important;
  padding: 0 5px;
}
.p-inplace .p-inplace-display {
  display: flex !important;
  justify-content: center;
  padding: 0 10px 0 5px;
  margin: 0 5px;
}

/* ===========Other Styles ============ */

.filter-switch {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.filter-switch .switch-grid {
  cursor: pointer;
  font-size: 20px;
}

.grid-active {
  color: rgb(70 206 132) !important;
}

.grid-add {
  display: flex;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
}

.grid-add p {
  font-size: var(--info-font-size);
  margin-left: 5px;
}

.table-column {
  display: flex;
  align-items: center;
}

.checked-icon {
  color: rgb(70 206 132) !important;
  font-size: calc(var(--info-font-size) + 2px);
  margin-right: 3px;
}

.filter .p-multiselect {
  background-color: transparent;
  border: none;
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0 !important;
  border-color: transparent;
}

.filter .p-multiselect {
  display: flex;
  flex-direction: row-reverse;
}

.filter .p-multiselect-label {
  padding: 5px;
  font-size: var(--info-font-size);
}

.p-multiselect-header .p-checkbox {
  width: 90%;
  border-radius: 0;
  display: flex;
  align-items: center;
}
.p-multiselect-header .p-checkbox::after {
  content: 'Выбрать все';
  margin-left: 5px;
  background-color: transparent !important;
}

.p-multiselect .p-checkbox.p-checkbox-checked:not(.p-checkbox-disabled):hover,
.p-multiselect
  .p-checkbox.p-checkbox-checked:not(.p-checkbox-disabled).p-checkbox-focused,
.p-checkbox:not(.p-checkbox-disabled).p-checkbox-focused {
  box-shadow: 0 0 0 0 transparent !important;
}

.modal-upload {
  font-size: calc(var(--info-font-size) + 2px);
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
  width: 100%;
  padding: 0.6rem 1rem;
  cursor: pointer;
}

.modal-docs {
  display: flex !important;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  font-size: calc(var(--info-font-size) + 2px);
  cursor: pointer;
}

.modal-docs_has {
  display: flex !important;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  font-size: calc(var(--info-font-size) + 2px);
  cursor: pointer;
}

.passport-data_img {
  width: calc(100% / 3.07);
  margin: 2px;
}

.modal-file {
  display: none;
}

.modal-upload .pi-upload::before {
  font-size: calc(var(--info-font-size) + 2px);
}

.modal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.modal-header .modal-action {
  cursor: pointer;
  transition: 0.4s ease-in-out all;
  padding: 5px 10px;
  border-radius: 4px;
}

.modal-header .modal-action span {
  color: var(--info-color);
}

.modal-action:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

@media (max-width: 978px) {
  .modal-header {
    flex-direction: column;
  }
  .modal-action {
    margin: 10px 0;
    display: flex;
    justify-content: center;
  }
}

.chip-title {
  font-size: calc(var(--info-font-size) + 2px);
  line-height: var(--info-line-height);
  padding: 5px 8px !important;
  margin: 2px 5px 0 0;
}

.chip-title .p-chip-text {
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
}

.add-button {
  background-color: transparent;
  color: var(--info-color);
}

.add-button span::before {
  margin: 2px 5px 0 0;
}

.add-button:hover,
.add-button:focus {
  background-color: transparent !important;
  color: var(--info-color) !important;
}

.chip {
  padding: 1px 10px !important;
  font-size: var(--info-font-size);
}

.chip-success {
  background-color: #7fff0082;
  color: #00a900;
}

.chip-danger {
  background-color: rgb(233 76 76 / 50%);
  color: #da0000;
}

.active-title {
  font-size: var(--info-font-size);
  line-height: var(--info-line-height);
  padding-bottom: 6px;
}

.table-nevo .p-dropdown {
  max-width: 80px;
}

.add-singer img {
  border-radius: 4px;
  height: 100%;
  padding: 15px;
  background-color: rgba(182, 182, 182, 0.2);
}
.add-singer input {
  display: none;
}

.template-download .chip-title {
  padding: 5px 15px !important;
  font-size: calc(var(--text-font-size) - 2px);
}

@media (max-width: 450px) {
  .panel-action-title {
    display: none;
  }
}

.no-permission-empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-permission-empty h2 {
  font-weight: lighter;
}

.create-staff_img {
  transition: 0.4s ease-in-out all;
  border-radius: 4px;
}

.create-staff_img:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
